import MuiBottomNavigation, {
  BottomNavigationProps as MuiBottomNavigationProps,
  // BottomNavigationAction as MuiBottomNavigationAction,
  // BottomNavigationActionProps as MuiBottomNavigationActionProps,
} from '@material-ui/core/BottomNavigation';
import MuiBottomNavigationAction, {
  BottomNavigationActionProps as MuiBottomNavigationActionProps,
} from '@material-ui/core/BottomNavigationAction';
import classnames from 'classnames';

import useStyles from './BottomNavigation.styles';
import withGenerateClassName from '../../../themes/withGenerateClassName';

interface IBottomNavigationProps extends MuiBottomNavigationProps {
  items: MuiBottomNavigationActionProps[];
  navbar_classname?: string;
  fixed_bottom?: boolean;
}

function BottomNavigation({
  items = [],
  navbar_classname,
  fixed_bottom,
  ...rest_props
}: IBottomNavigationProps) {
  const classes = useStyles();

  return (
    <MuiBottomNavigation
      classes={{
        root: classnames(
          classes.navbar__root,
          fixed_bottom && classes.navbar__fixed_bottom,
          navbar_classname
        ),
      }}
      showLabels
      {...rest_props}
    >
      {items.map((item) => (
        <MuiBottomNavigationAction
          classes={{
            root: classnames(classes.navlink__root),
            selected: classnames(classes.navlink__selected),
            wrapper: classnames(classes.navlink__wrapper),
            label: classnames(classes.navlink__label),
          }}
          {...item}
        />
      ))}
    </MuiBottomNavigation>
  );
}

export default withGenerateClassName(BottomNavigation);
